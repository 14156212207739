@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body{
  background-color: black;
}

@layer components {
  .my-custom-style {
    /* ... */
  }

  .btn-primary {
    @apply  inline-block w-full py-3 text-base font-bold  text-[#12221A] capitalize transition duration-150 ease-in-out bg-[#A5F33C] disabled:bg-[#557C1E] disabled:border-[#557C1E] disabled:text-[#213E28] disabled:hover:before:hidden rounded-full shadow-md px-7 hover:before:absolute hover:before:w-[80%] hover:before:h-[16px] hover:before:bottom-0 hover:before:bg-[#A5F33C] hover:before:z-[-1] hover:z-10 hover:before:left-[50%] hover:before:-translate-x-[50%] hover:relative hover:before:blur-[19px]  hover:shadow-lg focus:bg-[#A5F33C] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#A5F33C] active:shadow-lg;
  }
  .btn-primary-after {
    @apply  disabled:hover:before:hidden hover:before:absolute hover:before:w-[80%] hover:before:h-[16px] hover:before:bottom-0 hover:before:bg-[#A5F33C] hover:before:z-[-1] hover:z-10 hover:before:left-[50%] hover:before:-translate-x-[50%] hover:relative hover:before:blur-[19px]
   }


  .btn-primary-2 {
    @apply px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
  .btn-secondary {
    @apply inline-block w-full capitalize py-3 text-base font-bold   disabled:text-[#213E28] disabled:hover:before:hidden disabled:hover:bg-[#060C07] disabled:cursor-not-allowed disabled:border-[#557C1E] text-white  transition duration-150 ease-in-out bg-[#060C07] rounded-full shadow-md px-7 border-[1.5px] border-[#A5F33C] hover:bg-[#A5F33C] hover:border-[#A5F33C] hover:text-[#12221A] hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-lime-900 active:shadow-lg   hover:z-10  hover:relative ;
  }
  .menu-selected-before {
    @apply  before:top-0 before:left-0 before:absolute before:h-full before:w-1 before:bg-[#A5F33C] before:rounded-r 
  }
  .menu-selected-after {
    @apply  after:top-0 after:left-0 after:absolute after:h-full after:w-1 after:bg-[#A5F33C] after:rounded-r after:opacity-[0.5] after:blur-sm after:rounded-r
  }
   .background-border-rad-before {
    @apply before:top-0 before:left-0 before:absolute before:h-full before:w-[32px] before:bg-red/20 before:rounded-r 
  }

  .my-container {
    @apply xl:container md:max-w-[1150px] px-4 lg:px-16 m-auto  z-[1px] relative min-h-[calc(100vh-314px)];
}

  
  /* .imageContainer {
    @apply w-56 h-56;
    perspective: 1500px;
    @apply mx-auto;
    
  }
  .image-container img {
    @apply rounded-full;
    transform: rotate(0);
    transition: .3s;
  }
  .image-container:hover img {
    transform: rotateX(15deg) rotateY(-18deg) rotateZ(3deg);
  } */
}


@layer utilities {
  .background-linear-grad {
    background: linear-gradient(312.91deg, rgba(89, 194, 123, 0.09) 2.76%, rgba(247, 198, 68, 0.09) 40.09%, rgba(236, 86, 118, 0.09) 67.41%, rgba(20, 79, 245, 0.09) 101.67%);
  }
  .background-linear-gradient-border {
    background: linear-gradient(330deg, rgba(89, 194, 123, 0.7), rgba(247, 198, 68, 0.7), rgba(236, 86, 118, 0.7) , rgba(20, 79, 245, 0.7));
  }
  .background-linear-gradient-border-full {
    background: linear-gradient(330deg, rgba(89, 194, 123, 0.9), rgba(247, 198, 68, 0.9), rgba(236, 86, 118, 0.9) , rgba(20, 79, 245, 0.9));
  }
  .font-poppin {
    font-family: "Poppins", "sans-serif";
  }
  .background-border-rad {
    background: linear-gradient(93.36deg, rgba(255, 255, 255, 0.62) 6.61%, rgba(255, 255, 255, 0) 120.24%);
    
  }

  

  .step-line-after.active-step::after {
    content: '';
    position: absolute;
    top: 32px;
    left: 0;
    margin-left: 16px;
    width: 1.5px;
    height: 40px;
    background: #A5F33C ;
  }

  .step-line-after::after {
    content: '';
    position: absolute;
    top: 32px;
    left: 0;
    margin-left: 16px;
    width: 1px;
    height: 43px;
    background-color: #4D6553;
  }

  .step-line-after:last-child::after {
    display: none;
  }

  /* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 2px solid rgb(43, 43, 43); */
  -webkit-text-fill-color: white;
  /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
  transition: background-color 5000s ease-in-out 0s;
  caret-color: white;
  background: #12221A;
  /* font-size: 16px; */
}


}

.d-inherit {
  display: inherit;
}


/* scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  /* background: #E4EBF6;
   */
   background: #A5F33C;
  border: 0px none #ffffff;
  border-radius: 5rem;
}

::-webkit-scrollbar-thumb:hover {
  /* background: #E4EBF6; */
  background: #A5F33C;
}

::-webkit-scrollbar-thumb:active {
  /* background: #E4EBF6; */
  background: #A5F33C;
}

::-webkit-scrollbar-track {
  background: #060C07;
  border: 0px none transparent;
  border-radius: 0px;

  
}

::-webkit-scrollbar-corner {
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white;
    color: white;
}




.color-selector {
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid red;
  margin: 0 auto;
  min-width: 100%;
  border-radius: 4px;
  position: relative;
  padding: 12px 16px;
  background: white;
}
  .circle {
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
    border: 1px solid #eee;
    margin-right: 10px;
  }

  .hidden {
   
    left: 0;
    opacity: 0;
    width: 0;
    visibility: hidden;
    padding: 0;
    position: absolute;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

.colors-style {
  border: 1px solid red;
  border-radius: 4px;
  padding: 12px 16px;
 
}
.circle {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}